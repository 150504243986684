import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Contributor from "./Contributor";
import Loading from "./Loading";

export default class UserLocation extends React.Component {

    constructor() {
        super();

        this.state = {
            username: null,
            user: {
                location: null
            },
            loading: true
        };
    }

    fetchMoreData() {
        this.setState({loading: true});
        const username = this.state.username;
        fetch(`https://api.github.com/users/${username}`)
            .then(result => result.json())
            .then(
                result => {
                    console.log(result);
                    this.setState({
                        user: result,
                        loading: false
                    });
                },
                error => {
                    console.log("ERR");
                    this.setState({loading: false});
                });

    }

    componentDidMount() {
        this.state.username = this.props.username;
        this.fetchMoreData();
    }

    render() {
        console.log("UserLocation RENDER");
        if (this.state.loading) {
            return <Loading/>
        }

        return (
            <div className="userLocation">
                <a href="javascript:history.back()"> ⇦ Go Back </a>
                <h1>User Location</h1>
                <p>Username: {this.state.username}</p>
                <p>Location: {this.state.user.location}</p>
                <div>
                    <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.user.location}&size=600x300&key=AIzaSyDqplWbCDQ0fAaKWxhkw_H_VpOnKEkbGvQ`}
                         alt="" />
                </div>
            </div>
        );
    }
}
