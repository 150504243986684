import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Contributor from "./Contributor";
import Loading from "./Loading";

export default class ListPage extends React.Component {

    constructor() {
        super();

        this.state = {
            contributors: [],
            hasMore: true,
            page: 1,
            loading: true
        };
    }

    fetchMoreData() {
        this.setState({loading: true});
        const page = this.state.page;
        fetch(`https://api.github.com/repos/angular/angular/contributors?page=${page}&per_page=25`)
            .then(result => result.json())
            .then(
                result => {
                    console.log("ListPage fetchMoreData SUCC");
                    console.log(result);
                    this.setState({
                        contributors: this.state.contributors.concat(result),
                        page: this.state.page + 1
                    });
                    if (result.length < 25) {
                        this.setState({hasMore: false});
                    }
                    this.setState({loading: false});
                },
                error => {
                    console.log("ERR");
                    this.setState({loading: false});
                });

    }

    componentDidMount() {
        this.fetchMoreData();
    }

    render() {
        console.log("ListPage RENDER");
        return (
            <div className="listpage">
                <header>
                    <h1>Top Contributors</h1>
                </header>
                <InfiniteScroll
                    className="contributors"
                    dataLength={Math.ceil(this.state.contributors.length / 4)}
                    next={this.fetchMoreData.bind(this)}
                    hasMore={this.state.hasMore}
                    endMessage={
                        <p style={{textAlign: 'center'}}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                    {this.state.contributors.map(contributor => <Contributor key={contributor.id}
                                                                             contributor={contributor}/>)}
                </InfiniteScroll>

                {this.state.loading
                    ? <Loading/>
                    : null}
            </div>
        );
    }
}
