import React from "react";
import Compass from "./Compass";
import { Link } from "react-router-dom";

export default class Contributor extends React.Component {
    render() {
        /**
         * avatar,
         * user name,
         * number of commits,
         * a compass icon (button)
         * and an indicator presenting that the list item is selectable
         */
        return (
            <div className="contributor">
                <div className="contributor-inside">
                    <Link to={`/contributors/${this.props.contributor.login}/map`}>
                        <Compass/>
                    </Link>
                    <div className="avatar">
                        <img src={this.props.contributor.avatar_url} alt=""/>
                        <span className="email">@github</span>
                    </div>
                    <div className="login">
                        {this.props.contributor.login}
                    </div>
                    <div>
                        {this.props.contributor.contributions} commits
                    </div>
                    <div className="repositories">
                        <Link to={`/contributors/${this.props.contributor.login}`}>
                            <button>
                                View repositories
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
