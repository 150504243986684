import '../App.css';
import React from "react";
import ListPage from "./ListPage";
import UserPage from './UserPage';
import UserLocation from './UserLocation';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";


export default class App extends React.Component {

    constructor() {
        super();

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        console.log("App RENDER");
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route path="/contributors/:ghUserName/map" render={
                            (match) => <UserLocation username={match.match.params.ghUserName} />
                        } />
                        <Route path="/contributors/:ghUserName" render={
                            (match) => <UserPage username={match.match.params.ghUserName} />
                        } />
                        <Route path="/contributors">
                            <ListPage />
                        </Route>

                        <Route path="/">
                            <Redirect to="/contributors" />
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}
