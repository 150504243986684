import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "./Loading";


export default class UserPage extends React.Component {
    constructor() {
        super();

        this.state = {
            repos: [],
            page: 1,
            hasMore: true
        };
    }

    fetchMoreData() {
        this.setState({loading: true});
        const page = this.state.page;
        const username = this.props.username;
        const itemsPerPage = 25;
        fetch(`https://api.github.com/users/${username}/repos?page=${page}&per_page=${itemsPerPage}&sort=updated`)
            .then(result => result.json())
            .then(
                result => {
                    console.log("UserPage fetchMoreData SUCC");
                    console.log(result);
                    this.setState({
                        repos: this.state.repos.concat(result),
                        page: this.state.page + 1
                    });
                    if (result.length < itemsPerPage) {
                        this.setState({hasMore: false});
                    }
                    this.setState({loading: false});
                },
                error => {
                    console.log("ERR");
                    this.setState({loading: false});
                });

    }

    componentDidMount() {
        this.fetchMoreData();
    }

    render() {
        return (
            <div className="userpage">
                <a href="javascript:history.back()"> ⇦ Go Back </a>

                <h1>Last updated repos of user</h1>
                <InfiniteScroll
                    className="userpageScroll"
                    dataLength={this.state.repos.length+1}
                    next={this.fetchMoreData.bind(this)}
                    hasMore={this.state.hasMore}
                    endMessage={
                        <p style={{textAlign: 'center'}}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                    {this.state.repos.map(repo => <div key={repo.id}>
                        <a href={repo.html_url}>{repo.name}</a> ({repo.updated_at})
                    </div>)}
                </InfiniteScroll>

                {this.state.loading
                    ? <Loading/>
                    : null}
            </div>
        );
    }

}